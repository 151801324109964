import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { mapBlogs } from "../../lib/utility";
import BlogRoll from "../../components/BlogRoll";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import FindLO from "../../components/FindLO";
import Calculators from "../../components/Calculators";
import ProductDetails from "../../components/ProductDetails";
import Accent from "../../components/Accent";
import Content from "../../components/Content";

import background from "./images/va-refinance.jpg";
import icon from "./images/Icons_VA_active.png";

import styles from "./styles.module.less";

const VARefinance = ({ data }) => {
    const header = (
        <Header
            active={["current-homeowner", "refinance-mortgage", "va-refinance"]}
        />
    );

    const { corpBlogs } = data;
    const { edges: corpBlobPosts = [] } = corpBlogs || {};
    const corporateBlogs = mapBlogs(corpBlobPosts);

    return (
        <Layout hasHero={true} header={header}>
            <Helmet>
                <title>
                    VA Refinance - VA Home Loan Refinance | Bay Equity Home
                    Loans
                </title>
                <meta
                    name="description"
                    content="A VA streamline refinance is an interest rate reduction refinancing loan (IRRRL) designed to support military service members. Contact us to find out if you qualify."
                />
                <script
                    src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
            </Helmet>
            <div className={styles.productHero}>
                <div
                    className={styles.HeroImg}
                    style={{ backgroundImage: `url(${background})` }}>
                    <div className={styles.heroCopy}>
                        <div className={styles.iconContainer}>
                            <img
                                src={icon}
                                alt="VA Refinance (IRRRL) | Bay Equity Home Loans"
                            />
                        </div>
                        <div className={styles.copy}>
                            <div className={styles.header}>
                                <h1>{intro}</h1>
                            </div>
                            <Accent />
                            <Content
                                className={styles.subhead}
                                content={subhead}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ProductDetails
                title={"What is a VA streamline refinance?"}
                border={true}>
                <p>
                    Or maybe you’re asking what is a VA IRRRL? Well, to answer
                    both, a VA streamline refinance is an interest rate
                    reduction refinancing loan (IRRRL) designed to support past
                    and present military service members in refinancing their
                    homes. This special program is for homeowners who already
                    have a VA loan but would like to refinance that mortgage
                    with a lower interest rate.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"What are the benefits of a VA refinance?"}
                border={true}>
                <ul>
                    <li>No home appraisal required</li>
                    <li>No mortgage insurance required</li>
                    <li>Minimal documentation and approval requirements</li>
                    <li>
                        Use the lower interest rate to get a lower monthly
                        payment or turn the equity in your home into cash
                    </li>
                </ul>
            </ProductDetails>
            <ProductDetails
                title={"What are the current VA refinance rates?"}
                border={true}>
                <p>
                    Interest rates for VA home loan refinancing change all the
                    time based on market trends. While we can’t guarantee your
                    specific percentage here and now,{" "}
                    <a
                        href="/find-your-loan-officer"
                        style={{ color: "#FF8A00" }}>
                        talk to one of our loan officers
                    </a>{" "}
                    to lock in your exact interest rate.
                </p>
            </ProductDetails>
            <ProductDetails title={"How do I qualify for a VA refinance?"}>
                <p>
                    Qualifying for a VA streamline refinance takes more than
                    military service. Although it’s certainly a base requirement
                    (unremarried surviving spouses are also eligible), you will
                    need to have served the equivalent of one of the following
                    terms:
                </p>
                <ul>
                    <li>90 days of active service during wartime</li>
                    <li>181 days of active service during peacetime</li>
                    <li>
                        Six or more years of service in the National Guard or
                        Reserves
                    </li>
                </ul>
                <p>
                    <a
                        href="/find-your-loan-officer"
                        style={{ color: "#FF8A00" }}>
                        Talk to one of our loan officers
                    </a>{" "}
                    to talk about your VA refinancing options today.
                </p>
            </ProductDetails>
            <Calculators showCalculatorRefinance={true} />
            <FindLO />
            {corporateBlogs.length >= 3 && (
                <BlogRoll
                    corporateBlogs={corporateBlogs}
                    blogs={corporateBlogs}
                    showBlogs={true}
                    word="our"
                    url="va-refinance-(irrrl)"
                />
            )}
        </Layout>
    );
};

export default VARefinance;

const intro = "VA Home Loan Refinance";
const subhead = (
    <div>
        Designed to better serve
        <br /> those who serve us.
    </div>
);

export const pageQuery = graphql`
    query VARefinanceQuery {
        corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: {
                type: { eq: "corporate" }
                tags: { in: ["VA Refinance (IRRRL)"] }
            }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    tags
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                }
            }
        }
    }
`;
